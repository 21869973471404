<template>
  <tr class="">
    <td class="flex align-items-center">
      <div class="checkbox">
        <input type="checkbox" :id="'checkbox' + unique + tag.id" v-model="isChecked"/>
        <label :for="'checkbox' + unique + tag.id"><span class="checkbox-icon"></span></label>
      </div>
    </td>
    <td class="p-2 whitespace-nowrap font-semibold">
      <router-link :to="{name: 'TagDetail', params: {name: tag.slug}}">
        {{ tag.name }}
      </router-link>
    </td>
    <td class="p-2 whitespace-nowrap">
      <router-link :to="{name: 'UserDetail', params: {id: tag.user.id}}" v-if="tag.user">
        {{ tag.user.username }}
      </router-link>
    </td>
    <td class="p-2 whitespace-nowrap">
      {{ timeago(tag.created_at) }}
    </td>
    <td class="p-2 whitespace-nowrap">
      <router-link class="mr-3" :to="{name: 'TagDetail', params: {name: tag.slug}}">
        Xem
      </router-link>
      <a class="mr-3" href="javascript:void(0)" v-on:click="editTag(tag)">Sửa</a>
      <a href="javascript:void(0)" v-on:click="deleteTag(tag)">Xóa</a>
    </td>
  </tr>
</template>

<script>

import {
  timeago
} from "../../../core/services/utils.service";

export default {
  name: "AdminTagItem",
  props: {
    tag: Object,
    unique: String
  },
  data() {
    return {
      isChecked: false
    }
  },
  methods: {
    timeago(x) {
      return timeago(x);
    },
    editTag(tag) {
      this.$emit("editTag", tag);
    },
    deleteTag(tag) {
      this.$emit("deleteTag", tag);
    }
  },
  created() {
    this.isChecked = this.tag.is_checked;
  },
  watch: {
    "tag.is_checked": {
      handler(newVal) {
        this.isChecked = newVal;
      },
      deep: true
    },
    isChecked: {
      handler(newVal) {
        this.$emit("handleCheckedTag", this.tag, newVal);
      }
    },
  }
}
</script>
