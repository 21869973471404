<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="w-full space-y-7">
        <div class="card p-3">
          <div class="flex justify-between">
            <nav class="responsive-nav pl-2 is_ligh -mb-0.5 border-transparent h-12">
              <ul>
                <li>
                  <a>Tất cả<span>{{ allTagsCount }}</span></a>
                </li>
              </ul>
            </nav>
            
            <button uk-toggle="target: #add-tag-modal" class="whitespace-nowrap text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd"></path></svg> Thêm mới
            </button>
          </div>

          <form class="mt-4" v-on:submit.prevent="beforeProcessBatchTag">
            <div class="flex space-x-4">
              <input v-model="q" placeholder="Tìm kiếm" type="text" class="form-control shadow-none with-border"/>
              <select v-model="action" class="btn-group bootstrap-select shadow-none with-border">
                <option value="">-- Thao tác --</option>
                <option value="delete">Xóa</option>
              </select>
              <button :disabled="!selectedTagIds.length || !action" type="submit"
                      class="hover:bg-gray-300 bg-gray-200 rounded-md whitespace-nowrap px-4">Thực hiện
              </button>
            </div>
          </form>

          <div class="lg:mt-8 mt-2">
            <table class="w-full border-collapse">
              <thead>
              <tr class="border-b border-slate-200">
                <th class="flex align-items-center">
                  <div class="checkbox">
                    <input type="checkbox" id="checkbox" v-model="isCheckedAll"/>
                    <label for="checkbox"><span class="checkbox-icon"></span></label>
                  </div>
                </th>
                <th class="py-2 pr-2">Tên</th>
                <th class="py-2 pr-2">Đóng góp</th>
                <th class="py-2 pr-2">Ngày đăng</th>
                <th class="py-2 pr-2">Thao tác</th>
              </tr>
              </thead>
              <tbody class="divide-y" v-if="tags.data && tags.data.length">
              <tag-item v-on:deleteTag="beforeDeleteTag" v-on:editTag="editTag" unique="tags" :tag="tag" v-on:handleCheckedTag="handleCheckedTag"
                         v-for="(tag, index) in tags.data" :key="'tags' + index"/>
              </tbody>
            </table>

            <div class="mt-4">
              <v-pagination
                  class="justify-content-center"
                  v-if="tags.paginatorInfo.lastPage > 1"
                  v-model="tags.paginatorInfo.currentPage"
                  :pages="tags.paginatorInfo.lastPage"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="loadAllTags"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="add-tag-modal" class="create-post is-story" uk-modal>
    <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

      <div class="text-center py-3 border-b">
        <h3 class="text-lg font-semibold">Thêm tag mới</h3>
        <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
      </div>

      <div class="px-5 py-2">
        <textarea v-model="tagName" class="shadow-none with-border" placeholder="Nhập các tag, cách nhau bởi dấu phẩy (,) hoặc xuống dòng"></textarea>
      </div>

      <div class="flex items-center w-full justify-center border-t p-3">
        <a href="javascript:void(0)" v-on:click="createTags" class="btn-create-tags bg-green-600 hover:bg-green-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
          Thêm mới</a>
      </div>
    </div>
  </div>

  <div id="edit-tag-modal" class="create-post is-story" uk-modal>
    <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

      <div class="text-center py-3 border-b">
        <h3 class="text-lg font-semibold">Sửa tag</h3>
        <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
      </div>

      <div class="px-5 py-2">
        <input v-on:keyup.enter="updateTag" v-model="tag.name" class="shadow-none with-border" placeholder="Nhập tag" id="title" type="text" autocomplete="off">
      </div>

      <div class="flex items-center w-full justify-center border-t p-3">
        <button type="button" v-on:click="updateTag" class="btn-update-tag bg-green-600 hover:bg-green-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
          Cập nhật</button>
      </div>
    </div>
  </div>

  <div id="delete-tag-modal" class="create-post is-story" uk-modal>
    <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

      <div class="text-center py-3 border-b">
        <h3 class="text-lg font-semibold">Xóa tag</h3>
        <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
      </div>
      <div class="px-5 py-2">
        <p>Bạn có chắc chắn muốn xóa tag <strong>{{ tag.name }}</strong>?</p>
      </div>
      <div class="flex items-center w-full justify-center border-t p-3">
        <a href="javascript:void(0)" v-on:click="deleteTag" class="bg-red-600 hover:bg-red-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
          Xóa tag</a>
      </div>
    </div>
  </div>

  <div id="batch-tag-modal" class="create-post is-story" uk-modal>
    <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

      <div class="text-center py-3 border-b">
        <h3 class="text-lg font-semibold">Xử lý nhiều tag</h3>
        <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
      </div>
      <div class="px-5 py-2">
        <p>Bạn có chắc chắn muốn {{ actionName }} các tag đã chọn?</p>
      </div>
      <div class="flex items-center w-full justify-center border-t p-3">
        <a href="javascript:void(0)" v-on:click="processBatchTag" class="bg-blue-600 hover:bg-blue-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
          Thực hiện</a>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../core/services/api.service";
import TagItem from "./TagItem";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {changePageTitle, canManageTag, isAdmin} from "../../../core/services/utils.service";

export default {
  name: "AdminTagList",
  components: {TagItem, VPagination},
  data() {
    return {
      q: "",
      action: "",
      isCheckedAll: false,
      tags: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      allTagsCount: 0,
      selectedTagIds: [],
      reason: "",
      tagName: "",
      tag: {
        id: "",
        name: "",
        slug: ""
      },
      actionName: ""
    }
  },
  methods: {
    loadAllTags() {
      let where = {
        AND: []
      }

      if (this.q) {
        where.AND.push({column: "name", value: `%${this.q}%`, operator: "LIKE"});
      }

      let query = `query($page: Int, $where: WhereConditions) {
        tags(first:10, where: $where, page: $page, orderBy: [{column: "id", order:DESC}]) {
          data {
            id
            name
            slug
            created_at
            user {
              id
              username
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {where: where, page: this.tags.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.tags) {
              this.tags = data.data.tags;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadAllTagsCount() {
      let query = `query {
        tags(first:1, page: 1) {
          paginatorInfo {
            total
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.tags && data.data.tags.paginatorInfo) {
              this.allTagsCount = data.data.tags.paginatorInfo.total;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    handleCheckedTag(tag, isChecked) {
      this.tags.data.map((item) => {
        if (item.id === tag.id) {
          item.is_checked = isChecked;
          return false;
        }
      })
    },
    beforeProcessBatchTag() {
      window.$("#batch-tag-modal").addClass("uk-open");
    },
    processBatchTag() {
      let query = `mutation($ids: [ID!], $action: String!) {
        processBatchTag(ids: $ids, action: $action)
      }`;

      ApiService.graphql(query, {ids: this.selectedTagIds, action: this.action})
        .then(({data}) => {
          if (data.data && data.data.processBatchTag) {
            this.reRender();
            this.action = "";
            this.$toast.success("Xử lý thành công");
            window.$(".uk-close").click();
          } else if(data.errors) {
            this.$toast.error("Xử lý không thành công: " + data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error("Xử lý không thành công: " + response.message);
        });
    },
    createTags() {
      if (!this.tagName) {
        this.$toast.error("Bạn chưa nhập tên tag");
        return;
      }

      window.$(".btn-create-tags").attr("disabled", true);

      let query = `mutation($name: String!) {
      createTags(tags: $name) {
          id
        }
      }`;

      ApiService.graphql(query, {name: this.tagName})
        .then(({data}) => {
          if (data.data && data.data.createTags) {
            this.reRender();
            this.$toast.success("Thêm tag thành công");
            this.tagName = "";
            window.$(".uk-close").click();
          } else if(data.errors) {
            this.$toast.error("Thêm tag không thành công: " + data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error("Thêm tag không thành công: " + response.message);
        })
        .finally(() => {
          window.$(".btn-create-tags").removeAttr("disabled");
        });
    },
    updateTag() {
      if (!this.tag.name) {
        this.$toast.error("Bạn chưa nhập tên tag");
        return;
      }

      window.$(".btn-update-tag").attr("disabled", true);

      let query = `mutation($id: ID!, $name: String!) {
        updateTag(input: {id: $id, name: $name}) {
            id
          }
        }`;

      ApiService.graphql(query, {id: this.tag.id, name: this.tag.name})
          .then(({data}) => {
            if (data.data && data.data.updateTag) {
              this.reRender();
              this.$toast.success("Sửa tag thành công");
              this.tagName = "";
              window.$(".uk-close").click();
            } else if(data.errors) {
              this.$toast.error("Sửa tag không thành công: " + data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error("Sửa tag không thành công: " + response.message);
          })
          .finally(() => {
            window.$(".btn-update-tag").removeAttr("disabled");
          });
    },
    reRender() {
      this.isCheckedAll = false;
      this.selectedTagIds = [];
      this.loadAllTags();
      this.loadAllTagsCount();
    },
    editTag(tag) {
      this.setCurrentTag(tag);
      window.$("#edit-tag-modal").addClass("uk-open");
    },
    setCurrentTag(tag) {
      this.tag = tag;
    },
    beforeDeleteTag(tag) {
      this.setCurrentTag(tag);
      window.$("#delete-tag-modal").addClass("uk-open");
    },
    deleteTag() {
      let query = `mutation($id: ID!) {
        deleteTag(id: $id) {
          id
        }
      }`;
      ApiService.graphql(query, {id: this.tag.id})
        .then(({data}) => {
          if (data.data && data.data.deleteTag) {
            this.reRender();
            this.$toast.success("Xóa tag thành công");
            window.$(".uk-close").click();
          } else if(data.errors) {
            this.$toast.error("Xóa tag không thành công: " + data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error("Xóa tag không thành công: " + response.message);
        });
    },
  },
  mounted() {
    if (!canManageTag()) {
      this.$router.push({name: "Home"});
      return;
    }
    this.reRender();
    changePageTitle("Quản lý tag");
  },
  computed: {
    isAdmin() {
      return isAdmin();
    }
  },
  watch: {
    q: {
      handler() {
        this.tags.paginatorInfo.currentPage = 1;
        this.loadAllTags();
      }
    },
    isCheckedAll: {
      handler(newVal) {
        this.tags.data.map((tag) => {
          tag.is_checked = newVal;
        });
      }
    },
    "tags.data": {
      handler(newVal) {
        this.selectedTagIds = [];
        newVal.map((tag) => {
          if (tag.is_checked) {
            this.selectedTagIds.push(tag.id);
          }
        });
      },
      deep: true
    },
    action: {
      handler(newVal) {
        switch (newVal) {
          case "delete":
            this.actionName = "xóa";
            break;
        }
      },
      deep: true
    }
  }
}
</script>
